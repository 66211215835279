import { cx } from '@emotion/css'
import { useGeneralStore } from '../../../store'
import { CoverageMapStyles } from './CoverageMap'
import LoadingPopup from '../LoadingPopup'
import { DesktopWelcomeMapModal } from '../DesktopWelcomeMapModal'
import { useCoverageMapLogic } from './useCoverageMap'
import InnerCoverageMap from './InnerCoverageMap'
import { Address } from '../../../store/address'

export type ReducedCoverageMapProps = {
  addresses: Address[]
}
const ReducedCoverageMap = (props: ReducedCoverageMapProps) => {
  const {
    deckManagerRef,
    setGoogleMap,
    selectedCarrier,
    appExperience,
    focusMarker,
    onCloseWelcomeModal,
    trackMapInteraction,
    isWelcomeModalOpen
  } = useCoverageMapLogic()

  const [networkType] = useGeneralStore((state) => [state.networkType])

  return (
    <div
      onMouseDown={trackMapInteraction}
      onTouchStart={trackMapInteraction}
      className={cx(CoverageMapStyles, appExperience)}
    >
      <LoadingPopup deckManagerRef={deckManagerRef} />
      <InnerCoverageMap
        deckManagerRef={deckManagerRef}
        setGoogleMap={setGoogleMap}
        onClickMarker={focusMarker}
        selectedCarrier={selectedCarrier}
        networkType={networkType}
        addresses={props.addresses}
        zoom={12}
        minZoom={12}
      />
      <DesktopWelcomeMapModal isOpen={isWelcomeModalOpen} onClose={onCloseWelcomeModal} />
    </div>
  )
}

export default ReducedCoverageMap
