import { css } from '@emotion/css'
import { MOBILE_BREAKPOINT } from '../../utils/hooks/useWindowSize'

export const CoverageMapCardStyles = css`
  &.only-coverages {
    min-height: 300px;
  }

  background-color: white;
  border: 2px solid var(--goji-primary-black);
  border-radius: 10px;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 440px;

  @media (min-width: ${MOBILE_BREAKPOINT}) {
    margin: auto;
    margin-top: 56px;
  }
  width: 100%;
  max-width: 500px;

  .title {
    font-size: 22px;
    font-weight: 900;
    margin: 0 18px;
    > ion-skeleton-text {
      height: 22px;
    }
  }
  .subtitle {
    margin: 0 18px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--ion-color-medium);
    .icon {
      width: 16px;
      height: 16px;
    }
    .text {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .coverage-map {
    min-height: 200px;
    height: 100%;
    > div {
      height: 100%;
    }
  }
  .map-link {
    margin: 16px 18px 0;
    font-weight: 600;
    font-size: 14px;
    text-decoration: underline;

    > ion-skeleton-text {
      height: 14px;
      width: 30%;
    }
  }

  .error-container {
    padding: 32px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    img {
      width: 134px;
      height: 156px;
    }
    .error-message {
      font-size: 20px;
      font-weight: 600;
    }
  }
`

export const ChartStyles = css`
  margin: 0;
  padding: 0;
  > ion-row {
    padding: 0 18px;
    > ion-col {
      > ion-skeleton-text {
        height: 16px;
      }
    }
  }

  .description {
    margin-top: 8px;
    :not(:first-child) {
      margin-top: 16px;
    }
    margin-bottom: 8px;
    font-size: 14px;
    color: var(--ion-color-medium);
    text-align: end;
    > .carrier {
      text-align: start;
    }
    > .performance-title {
      text-align: start;
      margin-bottom: 8px;
    }
  }

  .value {
    height: 38px;
    align-items: center;
    width: 100%;
    padding: 0;
    :hover {
      background-color: var(--goji-color-secondary-blue);
    }
    > :first-child {
      margin-left: 18px;
    }
    > :last-child {
      margin-right: 18px;
    }
    .carrier-display {
      width: 100%;
    }
  }
`
