import { IonButton, IonCol, IonGrid, IonRow, IonSkeletonText } from '@ionic/react'
import Message from '../../pages/llm/components/Message'
import { ChartStyles, CoverageMapCardStyles } from './CoverageMapCard.styles'

export const LoadingMapCard = () => {
  return (
    <div className={CoverageMapCardStyles}>
      <span className='title'>
        <IonSkeletonText animated={true} />
      </span>
      <div className='subtitle'>
        <IonSkeletonText animated={true} />
      </div>
      <div className='coverage-map'>
        <IonSkeletonText animated={true} />
      </div>
      <IonGrid className={ChartStyles}>
        <IonRow>
          <IonCol>
            <IonSkeletonText animated={true} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonSkeletonText animated={true} />
          </IonCol>
        </IonRow>
      </IonGrid>
      <div className='map-link'>
        <IonSkeletonText animated={true} />
      </div>
    </div>
  )
}

export const AddressNotFoundMapMessage = () => {
  return (
    <Message from='bot'>
      Hmm, we couldn’t find the location you entered. Please double-check the address or try entering a nearby city or
      address.
    </Message>
  )
}

export const ErrorMapCard = ({ onRetry }: { onRetry: () => void }) => {
  return (
    <div className={CoverageMapCardStyles}>
      <div className='error-container'>
        <img src='/assets/images/barry/goji_barry_whoops.svg' alt='error' />
        <span className='error-message'>Oops! Something went wrong</span>
        <IonButton onClick={onRetry} color='primary' shape='round'>
          Try again
        </IonButton>
      </div>
    </div>
  )
}
