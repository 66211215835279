import React from 'react'
import { CoverageMapCardBackendInput, CoverageMapSuggestionHandler } from '../tools/handlers/CoverageMapToolHandler'
import { Address } from '../../../store/address'
import { Logger } from '../../../utils/Logger'
import { ChatComponentProps } from '../components/ChatComponent'
import { ChatTimelineProps } from '../components/ChatTimeline'
import { ChatInteraction, ChatStep, MessageType } from '../types/ChatStep'
import { ConversationalResponse, ToolCall, ValidatedResult, ValidatedResults } from '../ServerResponse'
import { ChatInterface, TOOL_NAME, ToolMap } from '../tools/const/tool.const'
import { FreeChatMessageRecord } from '../types/free-chat.types'
import { getInvalidMessages, OnMessagePayload, useSuggestionSelection } from './useChat'
import { ChatFlowSelector } from '../ChatFlow'
import { useHistory } from 'react-router'
import { getLastQuestionOrTool } from './getLastQuestion'
import { DefaultSuggestionToolHandler } from '../tools/handlers/DefaultSuggestionToolHandler'

type FailedResponse = { resultStatus: (typeof ValidatedResults)['FAILED']; error: unknown }
export type UseServerChatOptions = {
  // TODO: does server response change on server based chat?
  sendAnswerToLLM: (payload: { userAnswer: string }) => Promise<ConversationalResponse | FailedResponse>
  onMessage: ChatInterface<unknown>['onMessage']
  timeline: ChatTimelineProps['timeline']
}

export function useServerChat({
  sendAnswerToLLM,
  onMessage,
  timeline
}: UseServerChatOptions): ChatComponentProps['chat'] {
  const lastQuestion = React.useMemo((): ChatStep | undefined => {
    return getLastQuestionOrTool(timeline)
  }, [timeline])

  const history = useHistory()

  const getChatInterface = React.useCallback(
    <T extends ToolCall<unknown> | undefined>(toolCall?: T) => {
      return {
        onMessage,
        // TODO: how to infer the type of toolCall?
        toolCall: toolCall as T,
        getTimeline: () => timeline,
        pushHistory: (path: string) => {
          history.push(path)
        }
      }
    },
    [history, onMessage, timeline]
  )

  const handleToolCall = React.useCallback(
    (toolCall: ToolCall<any>) => {
      const ToolHandler = ToolMap[toolCall.name]?.ToolHandler
      const chatInterface = getChatInterface(toolCall)
      if (ToolHandler) {
        new ToolHandler(chatInterface).handle(chatInterface)
      } else {
        Logger.error('Tool call not implemented:', toolCall)
        new DefaultSuggestionToolHandler(chatInterface).handleUnknownTool()
      }
    },
    [getChatInterface]
  )

  const reactToServerResponse = React.useCallback(
    (
      serverResponse: ConversationalResponse | FailedResponse,
      responseMetadata?: { isSuggestionResponse?: boolean; userAnswer?: string }
    ) => {
      if (!lastQuestion) {
        Logger.error('could not find last question')
        return
      }

      let invalidMessages = getInvalidMessages({
        validatedResult: serverResponse.resultStatus,
        // TODO: how to get custom out of context responses?
        outOfContextResponse: 'Sorry, I don’t understand your question. What can I help you with?',
        lastQuestion,
        // TODO: how to get custom invalid responses?
        invalidResponse: '',
        userAnswer: responseMetadata?.userAnswer
      })

      if (invalidMessages) {
        invalidMessages.forEach((message) => {
          onMessage({ messageRecord: { message } as FreeChatMessageRecord })
        })
        return
      }

      if (serverResponse.resultStatus === ValidatedResults.FAILED) {
        Logger.error('server response failed and was not caught', serverResponse)
        return
      }

      if (serverResponse.toolCalls) {
        serverResponse.toolCalls.forEach((toolCall) => {
          handleToolCall(toolCall)
        })
        return
      }

      if (serverResponse.message) {
        onMessage({
          messageRecord: { message: { type: MessageType.TEXT, from: 'bot', text: serverResponse.message } }
        })
      } else {
        onMessage({
          messageRecord: {
            message: {
              from: 'bot',
              type: MessageType.STEP,
              question: [{ text: 'I’m not sure how to help with that, try selecting an action below.' }],
              suggestions: ChatFlowSelector.question__start_plan_finder.suggestions,
              interactions: [ChatInteraction.FREE_TEXT]
            }
          }
        })
      }
    },
    [lastQuestion, onMessage, handleToolCall]
  )
  const onChatSend = React.useCallback(
    (text: string) => {
      if (lastQuestion) {
        sendAnswerToLLM({ userAnswer: text }).then((res) =>
          reactToServerResponse(res, { isSuggestionResponse: false, userAnswer: text })
        )
      } else {
        Logger.error('could not find last question in timeline: ', lastQuestion)
      }

      onMessage({
        messageRecord: {
          message: {
            text,
            from: 'user',
            type: MessageType.TEXT
          }
        }
      })
    },
    [lastQuestion, reactToServerResponse, onMessage, sendAnswerToLLM]
  )

  const onAddressSend = React.useCallback(
    async (prediction: google.maps.places.AutocompletePrediction) => {
      onMessage({
        messageRecord: {
          message: {
            type: MessageType.TEXT,
            from: 'user',
            text: prediction.description
          }
        }
      })

      const backendInput: CoverageMapCardBackendInput = {
        isCurrentLocation: false,
        knownLocation: null,
        isMissingLocation: false,
        placeId: prediction.place_id,
        address: null,
        carrier: null,
        carrierId: null,
        networkType: '5g',
        carrierStatus: null,
        wantsBestCarrier: false
      }

      reactToServerResponse({
        message: null,
        resultStatus: ValidatedResults.VALID,
        toolCalls: [
          {
            name: 'CoverageMap',
            input: backendInput
          }
        ]
      })
    },
    [onMessage, reactToServerResponse]
  )

  const onSuggestionResponse = React.useCallback(
    (
      serverResponse: { resultStatus: ValidatedResult; address?: Address; parsedAnswer: any },
      options?: { isSuggestionResponse?: boolean; userAnswer?: string }
    ) => {
      if (serverResponse.address) {
        const chatInterface = getChatInterface(undefined)
        new CoverageMapSuggestionHandler(serverResponse.address, chatInterface).addToolMessages()
      } else {
        reactToServerResponse({ resultStatus: serverResponse.resultStatus, message: null, toolCalls: [] }, options)
      }
    },
    [reactToServerResponse, getChatInterface]
  )

  const onSuggestionMessage = React.useCallback(
    (payload: OnMessagePayload) => {
      if (payload.messageRecord.toolSuggestion) {
        switch (payload.messageRecord.toolSuggestion) {
          case TOOL_NAME.COVERAGE_MAP:
            onMessage({
              messageRecord: {
                message: ChatFlowSelector.coverage__start
              }
            })
            break
          case TOOL_NAME.NETWORK_METER:
            onMessage({
              messageRecord: {
                message: ChatFlowSelector.meter__start
              }
            })
            break
          default:
            break
        }
        return
      }
      if (payload.isSuggestionResponse) {
        onMessage(payload)
        return
      }
      if (payload.messageRecord.message.type === MessageType.TEXT) {
        onChatSend(payload.messageRecord.message.text)
      }
    },
    [onChatSend, onMessage]
  )

  const { onSuggestionSelected } = useSuggestionSelection({
    onResponse: onSuggestionResponse,
    onMessage: onSuggestionMessage
  })

  return {
    // TODO: return something useful
    onChatSend,
    lastQuestion,
    onAddressSend,
    onSuggestionSelected,
    timeline
  }
}
