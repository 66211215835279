import { useQuery, UseQueryResult } from 'react-query'
import shallow from 'zustand/shallow'
import API from '../api'
import { GeoJsonRecord } from '../components/CoverageMap/Geodata.service'
import { useBoundStore } from '../store'
import { Carrier, CarrierApiResult } from '../store/carrierSelector'
import { getBoosted, loadPairs } from './Bin.service'
import { defaultCarriers } from './Carrier.const'
import { queryClient } from '../queryClient'
import { getNetworkTypeId, NetworkTypeValue } from './NetworkType.service'
import { excludeCarriersFromOtherRegions, useAddressesStates } from '../utils/hooks/useGetRegionFilter'
import { Address } from '../store/address'
import { Plan } from './planService/Plan'
import { getNetworkPerformanceAverages } from './planService/Plan.service'

export const CarrierService = {
  get: {
    bestCarriers: async (addresses: Address[], technology?: NetworkTypeValue): Promise<GeoJsonRecord[]> => {
      if (!addresses.length) {
        return []
      }

      const points = addresses.map((address) => {
        return {
          lat: address.latitude,
          lon: address.longitude
        }
      })

      const hasTechnology = technology !== undefined
      const backendTechnology = hasTechnology ? getNetworkTypeId(technology) : undefined

      const bestCarriers = await API.get(
        '/point_signals?points=' + JSON.stringify(points) + (hasTechnology ? '&networkTypeId=' + backendTechnology : '')
      )

      return bestCarriers as GeoJsonRecord[]
    },
    /**
     * Get carrier by id. If the all-carriers query is already in the cache, it will use that data to find the carrier.
     * Otherwise, it will make a request to the API to get the carrier.
     */
    carrier: async (carrierId: string) => {
      const allCarriers = queryClient.getQueryData<Carrier[]>('all-carriers')
      if (allCarriers) {
        const carrier = allCarriers.find((c) => c.id === carrierId)
        if (carrier) {
          return carrier
        }
      }
      const carrierRes = (await API.get('/carriers?carrierId=' + carrierId)) as CarrierApiResult[]
      const carrier = carrierRes[0]
      const logo_url = carrier.logo_name
        ? `${process.env.REACT_APP_PUBLIC_ASSETS_S3_BUCKET_URL}/carrier-logos/${carrier.logo_name}`
        : undefined
      return {
        ...carrier,
        logo_url: logo_url
      } as Carrier
    },
    allCarriers: async () => {
      const carriersRes = (await API.get('/carriers/all')) as CarrierApiResult[]
      return carriersRes.map((carrier) => {
        const logo_url = carrier.logo_name
          ? `${process.env.REACT_APP_PUBLIC_ASSETS_S3_BUCKET_URL}/carrier-logos/${carrier.logo_name}`
          : undefined
        return {
          ...carrier,
          logo_url: logo_url
        } as Carrier
      })
    },
    carriersWithRecords: async () => {
      const carriersRes = (await API.get('/carriers?filterCarriersWithoutData=true')) as Carrier[]
      return carriersRes.map((carrier) => {
        const defaultCarrier = defaultCarriers.find((dc) => dc.name === carrier.es_id)
        return {
          ...carrier,
          logo_url: defaultCarrier?.logo_url
        }
      })
    }
  }
}

export function getBestCarrier<
  T extends {
    properties: Pick<
      GeoJsonRecord['properties'],
      'network_performance' | 'network_type_id' | 'carrier_name' | 'carrier_id'
    >
  }
>(bestCarriers: T[]): Carrier | undefined {
  const bestCarrier = bestCarriers.sort(
    (a, b) => (b.properties.network_performance || 0) - (a.properties.network_performance || 0)
  )[0]

  if (!bestCarrier) {
    return
  }

  const defaultCarrier = defaultCarriers.find((dc) => dc.name === bestCarrier.properties.carrier_name)

  return {
    id: bestCarrier.properties.carrier_id,
    name: bestCarrier.properties.carrier_name,
    amplitude_name: bestCarrier.properties.carrier_name,
    logo_url: defaultCarrier?.logo_url ?? '',
    has_data: true
  }
}

export const UseCarrier = {
  useAvailableInMyRegion: (carriersQuery: UseQueryResult<Carrier[]>) => {
    const [addresses] = useBoundStore((state) => [state.addresses], shallow)
    const statesQuery = useAddressesStates(addresses)

    const carriers = carriersQuery.data

    return useQuery({
      queryKey: ['available-in-my-region', carriers],
      enabled: !!carriersQuery.data && !!statesQuery.data,
      staleTime: Infinity,
      queryFn: () => {
        return excludeCarriersFromOtherRegions(carriersQuery.data!, statesQuery.data!)
      }
    })
  },
  useBestCarriers: ({
    addresses: addressesParam,
    technology
  }: { addresses?: Address[]; technology?: NetworkTypeValue } = {}) => {
    const [storeAddresses] = useBoundStore((state) => [state.addresses], shallow)
    const addresses = addressesParam || storeAddresses

    const queryKey = 'best-carriers/' + addresses.map((a) => `(${a.latitude}, ${a.longitude})`).join(',') + technology
    return useQuery({
      queryKey,
      staleTime: Infinity,
      queryFn: async () => {
        const carrierRecords = await CarrierService.get.bestCarriers(addresses, technology)
        if (carrierRecords.every((carrierRecord) => carrierRecord.properties.network_type_id === 0)) {
          return []
        }
        carrierRecords.forEach((carrierRecord) => {
          const pair = Object.values(loadPairs).find((pair) => pair.binSize === carrierRecord.properties.zoom)
          if (!pair) {
            return
          }
          carrierRecord.properties.network_performance = getBoosted(
            pair.z,
            carrierRecord.properties.network_performance
          )
        })
        return carrierRecords
      }
    })
  },
  useBestCarrier: ({ addresses }: { addresses?: Address[] } = {}) => {
    const bestCarriers = UseCarrier.useBestCarriers({ addresses })
    const bestCarrier = bestCarriers.data?.length ? bestCarriers.data[0] : undefined

    if (!bestCarrier) {
      return undefined
    }

    const defaultCarrier = defaultCarriers.find((dc) => dc.name === bestCarrier?.properties.carrier_name)
    return {
      id: bestCarrier?.properties.carrier_id,
      name: bestCarrier?.properties.carrier_name,
      amplitude_name: bestCarrier?.properties.carrier_name,
      logo_url: defaultCarrier?.logo_url,
      has_data: true
    }
  },
  useBestCarrierWithPerformance: ({ addresses }: { addresses?: Address[] } = {}) => {
    const bestCarrierGeoJSON = UseCarrier.useBestCarrier({ addresses })
    return UseCarrier.useCarrierWithPerformance({ carrierId: bestCarrierGeoJSON?.id, addresses })
  },
  useBestCarrierForTechnology: ({ addresses, technology }: { addresses?: Address[]; technology: NetworkTypeValue }) => {
    const bestCarriersQuery = UseCarrier.useBestCarriers({ addresses, technology })
    const bestCarrierForTechnologyQuery = useQuery({
      queryKey: ['best-carrier-for-technology', addresses, technology],
      enabled: bestCarriersQuery.isFetched,
      queryFn: () => {
        if (!bestCarriersQuery.data?.length) {
          return
        }
        return getBestCarrier(bestCarriersQuery.data)
      }
    })

    return bestCarrierForTechnologyQuery
  },
  useAllCarriers: () => {
    return useQuery({
      queryKey: ['all-carriers'],
      staleTime: Infinity,
      queryFn: () => CarrierService.get.allCarriers()
    })
  },
  useCarriersWithRecords: () => {
    const allCarriers = UseCarrier.useAllCarriers()

    return useQuery({
      queryKey: 'carrier-with-records',
      enabled: allCarriers.isFetched,
      staleTime: Infinity,
      queryFn: () => {
        if (!allCarriers.data) {
          return []
        }
        return allCarriers.data.filter((carrier) => carrier.has_data)
      }
    })
  },
  useCarrier: (carrierId: string | undefined) => {
    const carriersQuery = UseCarrier.useAllCarriers()

    return useQuery({
      queryKey: ['carrier', carrierId],
      enabled: !!carrierId && carriersQuery.isFetched,
      staleTime: Infinity,
      queryFn: () => {
        return carriersQuery.data?.find((c) => c.id === carrierId)
      }
    })
  },
  useCarrierWithPerformance: ({ carrierId, addresses }: { carrierId: string | undefined; addresses?: Address[] }) => {
    const currentCarrier = UseCarrier.useCarrier(carrierId)
    const bestCarriers = UseCarrier.useBestCarriers({ addresses })

    return useQuery({
      queryKey: ['carrier-performance', carrierId, addresses],
      enabled: currentCarrier.isFetched && bestCarriers.isFetched,
      staleTime: Infinity,
      queryFn: () => {
        if (currentCarrier.error) {
          throw currentCarrier.error
        }
        if (bestCarriers.error) {
          throw bestCarriers.error
        }

        const networkOperators = currentCarrier.data?.network_operators

        if (!bestCarriers.data || !networkOperators) {
          return
        }
        const filteredCarriers = bestCarriers.data.filter((carrier) =>
          networkOperators.includes(carrier.properties.carrier_id)
        )

        return {
          carrier: currentCarrier.data,
          networkPerformance: getNetworkPerformanceAverages(filteredCarriers)
        }
      }
    })
  },
  useCarriersWithPerformance: ({ carrierIds, addresses }: { carrierIds: string[]; addresses?: Address[] }) => {
    const carriersQuery = UseCarrier.useAllCarriers()
    const bestCarriers = UseCarrier.useBestCarriers({ addresses })

    return useQuery({
      queryKey: ['carriers-performance', carrierIds, addresses],
      enabled: carriersQuery.isFetched && bestCarriers.isFetched,
      staleTime: Infinity,
      queryFn: () => {
        if (carriersQuery.error) {
          throw carriersQuery.error
        }
        if (bestCarriers.error) {
          throw bestCarriers.error
        }

        const carriers = carriersQuery.data?.filter((c) => carrierIds.includes(c.id))
        return carriers?.map((carrier) => {
          const networkOperators = carrier.network_operators
          if (!networkOperators) {
            return {
              carrier,
              networkPerformance: undefined,
              noData: true,
              noCoverage: false
            }
          }

          const filteredCarriers = bestCarriers.data?.filter((bestCarrier) =>
            networkOperators.includes(bestCarrier.properties.carrier_id)
          )

          const networkPerformance = filteredCarriers?.length
            ? getNetworkPerformanceAverages(filteredCarriers)
            : undefined

          return {
            carrier,
            networkPerformance,
            noData: filteredCarriers?.length === 0,
            noCoverage: networkPerformance?.average4GPerformance === 0 && networkPerformance?.average5GPerformance === 0
          }
        })
      }
    })
  },
  useCarriersSplit: (carriersQuery: UseQueryResult<Carrier[]>) => {
    const allCarriers = UseCarrier.useAllCarriers()
    const bestCarriers = UseCarrier.useBestCarriers({ addresses: [] })
    const bestCarrierId = bestCarriers.data?.length ? bestCarriers.data[0].properties.carrier_id : undefined

    return useQuery({
      queryKey: ['default-carriers', carriersQuery.data],
      enabled: !allCarriers.isLoading && !bestCarriers.isLoading && !!carriersQuery.data,
      staleTime: Infinity,
      queryFn: () => {
        const { defaultCarriersResult, otherCarriersResult } = splitDefaultCarriers(carriersQuery.data!)
        moveBestToDefaultCarriers(otherCarriersResult, defaultCarriersResult, bestCarrierId)

        return { defaultCarriers: defaultCarriersResult, otherCarriers: otherCarriersResult }
      }
    })
  },
  useBestCarrierOperatorId: (
    { carrierId, addresses }: { carrierId?: string; addresses: Address[] } = { addresses: [] }
  ) => {
    const currentCarrier = UseCarrier.useCarrier(carrierId)
    const bestCarriers = UseCarrier.useBestCarriers({ addresses })
    const carrierOperators = currentCarrier.data?.network_operators

    if (!carrierOperators?.length) {
      return carrierId
    } else if (carrierOperators.length === 1) {
      return carrierOperators[0]
    }

    const sortedCarriers = bestCarriers.data?.sort(
      (a, b) => (b.properties.network_performance || 0) - (a.properties.network_performance || 0)
    )

    const bestCarrier = sortedCarriers?.find((carrier) => carrierOperators.includes(carrier.properties.carrier_id))
    const operator = bestCarrier?.properties.carrier_id ?? carrierOperators[0] ?? carrierId
    return operator
  }
}

export function useSupportedCarriers() {
  const supportedCarriers = UseCarrier.useCarriersWithRecords()
  const isPlanCarrierSupported = (plan: Plan) => {
    if (!supportedCarriers.data) {
      return false
    }
    if (plan.networkPerformance4G !== undefined) {
      return true
    }
    return supportedCarriers.data.some((carrier) => carrier.id === plan.carrierId)
  }

  return { supportedCarriers, isPlanCarrierSupported }
}

function moveBestToDefaultCarriers(
  otherCarriers: Carrier[],
  defaultCarriers: Carrier[],
  bestCarrierId: string | undefined
) {
  const otherIndex = otherCarriers.findIndex((carrier) => carrier.id === bestCarrierId)
  const carrierFound = otherCarriers[otherIndex]
  if (otherIndex !== -1) {
    otherCarriers.splice(otherIndex, 1)
    defaultCarriers.push(carrierFound)
  }
}

function splitDefaultCarriers(carriers: Carrier[]) {
  const defaultCarriersResult: Carrier[] = []
  const otherCarriersResult: Carrier[] = []

  for (const carrier of carriers) {
    const newCarrier = defaultCarriers.find((c) => c.name === carrier.name)
    if (newCarrier) {
      defaultCarriersResult.push({
        ...newCarrier,
        id: carrier.id,
        amplitude_name: carrier.amplitude_name,
        has_data: carrier.has_data
      })
    } else {
      otherCarriersResult.push(carrier)
    }
  }

  return { defaultCarriersResult, otherCarriersResult }
}
