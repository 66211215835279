import { cx } from '@emotion/css'
import { IonButton, IonPage } from '@ionic/react'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import API from '../../../api'
import { ChatbotContentContainerStyles } from '../ChatbotPage.styles'
import { ChatComponent } from '../components/ChatComponent'
import { ChatTimelineProps } from '../components/ChatTimeline'
import { useServerChat } from '../hooks/useServerChat'
import { ConversationalResponse, ValidatedResults } from '../ServerResponse'
import { MessageType } from '../types/ChatStep'
import { FreeChatPageStyles } from './FreeChatPageStyles'
import { Logger } from '../../../utils/Logger'
import { useMutationBuffer } from './hooks/useMutationBuffer'
import { FreeChat, getDefaultTimeline } from '../utils/chatApiSource'
import { FreeChatSourceSet } from '../utils/source-sets'
import useAuthStore from '../../../store/auth'
import { ToolName } from '../tools/const/tool.const'
import { OnMessagePayload } from '../hooks/useChat'
import { RoutePaths } from '../../../paths'

interface ServerChatPayload {
  answer: string
  context: (
    | {
        userAnswer: string
      }
    | { assistantAnswer: string; suggestions?: string[] }
    | {
        toolCall: {
          name: ToolName
          input: Record<string, any>
        }
      }
  )[]
}
function useSendAnswerToLLM() {
  return useMutation(
    'sendAnswerToLLM',
    async (options: { userAnswer: string; timeline: ChatTimelineProps['timeline'] }) => {
      const serverPayload: ServerChatPayload = {
        answer: options.userAnswer,
        context: options.timeline.map((message) => {
          if (message.type === MessageType.STEP) {
            return {
              assistantAnswer: message.question.map((q) => q.text).join(''),
              suggestions: message.suggestions?.map((s) => s.text)
            }
          }

          if (message.type === MessageType.TOOL) {
            return {
              toolCall: {
                name: message.tool,
                input: message.backendInput
              }
            }
          }

          if (message.from === 'bot') {
            return { assistantAnswer: message.text }
          }

          return { userAnswer: message.text }
        })
      }
      try {
        const response: ConversationalResponse = await API.post('/llm/chat', serverPayload as Record<string, any>)
        return response
      } catch (error) {
        return { resultStatus: ValidatedResults.FAILED, error }
      }
    }
  )
}

const freeChatQueryKey = ['free-chat']
function useFreeChat() {
  const user = useAuthStore((state) => state.currentUser)
  const dataSource = user ? FreeChatSourceSet.remoteSource : FreeChatSourceSet.localSource

  const queryClient = useQueryClient()
  function updateCache(timeline: ChatTimelineProps['timeline']) {
    const freeChat: FreeChat = {
      chatTimeline: timeline
    }
    queryClient.setQueryData(freeChatQueryKey, freeChat)
  }

  const mutation = useMutation({
    mutationKey: ['free-chat'],
    mutationFn: async (timeline: ChatTimelineProps['timeline']) => {
      const freeChat: FreeChat = {
        chatTimeline: timeline
      }

      const response = await dataSource.setStorage(freeChat)
      return response
    },
    onMutate: async (freeChat) => {
      await queryClient.cancelQueries('free-chat')
      updateCache(freeChat)
    },
    onError: (error) => {
      Logger.error('cannot persist chat', error)
    },
    onSettled: (result) => {
      if (result) {
        updateCache(result.chatTimeline)
      }
    }
  })

  const getState = React.useCallback(() => {
    return queryClient.getQueryData<FreeChat>(freeChatQueryKey)?.chatTimeline || []
  }, [queryClient])

  const { pushToBuffer } = useMutationBuffer(getState, mutation)

  const query = useQuery({
    queryKey: freeChatQueryKey,
    keepPreviousData: true,
    staleTime: 60 * 1000 * 3,
    queryFn: async () => {
      const response = await dataSource.getStorage()
      return response || ({ chatTimeline: getDefaultTimeline() } as FreeChat)
    }
  })

  return {
    query,
    mutate: pushToBuffer
  }
}

type FreeChatErrorBoundaryProps = { resetChat: () => void; children: React.ReactNode }
class FreeChatErrorBoundary extends React.Component<FreeChatErrorBoundaryProps> {
  state: { hasError: false }

  constructor(props: FreeChatErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  resetChat() {
    this.props.resetChat()
    requestAnimationFrame(() => {
      this.setState({ hasError: false })
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <section style={{ color: '#000' }}>
          <h1>Something went wrong.</h1>
          <div>Your current chat state might be broken</div>
          <IonButton routerLink={RoutePaths.root}>Go to the home page</IonButton>
          <IonButton onClick={() => this.resetChat()}>Reset chat state</IonButton>
        </section>
      )
    }
    return this.props.children
  }

  static getDerivedStateFromError(_error: Error) {
    return { hasError: true }
  }
}

export type FreeChatPageProps = {
  autocompleteService?: google.maps.places.AutocompleteService
}

export function FreeChatPage(props: FreeChatPageProps) {
  const freeChat = useFreeChat()

  const sendAnswerToLLMMutation = useSendAnswerToLLM()

  const queryClient = useQueryClient()

  const sendAnswerToLLM = React.useCallback(
    async (payload: { userAnswer: string }) => {
      const timeline = queryClient.getQueryData<FreeChat>(freeChatQueryKey)?.chatTimeline || []
      const result = await sendAnswerToLLMMutation.mutateAsync({ userAnswer: payload.userAnswer, timeline })
      return result
    },
    [sendAnswerToLLMMutation, queryClient]
  )

  const mutateFreeChat = freeChat.mutate
  const onMessage = React.useCallback(
    async (payload: OnMessagePayload) => {
      mutateFreeChat({
        action: 'push',
        state: [payload.messageRecord.message]
      })
    },
    [mutateFreeChat]
  )

  const chat = useServerChat({
    onMessage,
    sendAnswerToLLM,
    timeline: freeChat.query.data?.chatTimeline || []
  })

  const restart = React.useCallback(() => {
    mutateFreeChat({ action: 'replace', state: getDefaultTimeline() })
  }, [mutateFreeChat])

  return (
    <IonPage className={cx(FreeChatPageStyles, ChatbotContentContainerStyles)}>
      <FreeChatErrorBoundary resetChat={restart}>
        <ChatComponent
          autocompleteService={props.autocompleteService}
          title='Chat with Barry'
          onRestart={restart}
          isLoading={sendAnswerToLLMMutation.isLoading || !freeChat.query.data}
          chatStatus={'active'}
          chat={chat}
        />
      </FreeChatErrorBoundary>
    </IonPage>
  )
}
