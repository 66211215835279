import { Logger } from '../../../utils/Logger'

export function createLocalStorageSource<T>(lsKey: string) {
  return {
    getStorage: async () => {
      const lsItem = localStorage.getItem(lsKey)
      if (lsItem) {
        try {
          return JSON.parse(lsItem) as T
        } catch (e) {
          Logger.error('Error parsing chat storage from local storage', e)
        }
      }
    },
    setStorage: async (value: T | undefined) => {
      if (!value) {
        localStorage.removeItem(lsKey)
      } else {
        localStorage.setItem(lsKey, JSON.stringify(value))
      }
    }
  }
}
