import { RoutePaths } from '../../paths'
import { ContractType, ReasonForSwitch } from '../../services/wizard/reason-for-switch'
import { FeatureFlag } from '../../utils/FeatureFlags'
import { DeepMutable } from '../../utils/types/DeepMutable'
import { ValidatedResults } from './ServerResponse'
import { TOOL_NAME } from './tools/const/tool.const'
import {
  BotTextMessage,
  ChatInteraction,
  IChatFlow,
  MessageType,
  outOfContextTemplate,
  getInvalidResponseParams
} from './types/ChatStep'
import { FreeChatMessageRecord } from './types/free-chat.types'
import { SuggestionType } from './types/Suggestion'

export type PredefinedChatBotMessageRecord = { message: BotTextMessage }

export type PredefinedChatKeyRecord<T extends IChatFlow> = {
  key: keyof T
}

export type PredefinedChatMessageRecord<T extends IChatFlow> = FreeChatMessageRecord | PredefinedChatKeyRecord<T>

const ChatFlowConst = {
  entrypoint: {
    type: MessageType.TRANSITION,
    from: 'bot',
    imageUrl: '/assets/images/barry/barry_icon_ok_hand.svg',
    imageAlt: 'goji barry signaling ok with his hand',
    text: 'Hi there, I’m Barry!',
    subtext: 'Let’s find you the perfect phone plan.',
    nextMessageKey: 'address_input__replacement'
  },

  address_input__replacement: {
    from: 'bot',
    type: MessageType.STEP,
    endpoint: 'empty',
    question: [
      {
        text: "First, let's make sure there's coverage for your home or current location."
      }
    ],
    outOfContextResponse: `${outOfContextTemplate}your address input?`,
    subtitle: 'You can change this or add more locations later.',
    suggestions: [
      {
        type: SuggestionType.CURRENT_LOCATION,
        text: 'Current location',
        value: {
          parsedAnswer: 'Current location'
        }
      }
    ],
    interactions: [ChatInteraction.ADDRESS],
    nextPaths: {
      $default: 'current_plan__transition__few_more_questions'
    }
  },

  current_plan__transition__few_more_questions: {
    type: MessageType.TRANSITION,
    from: 'bot',
    imageUrl: '/assets/images/barry/barry_icon_thinking.svg',
    imageAlt: 'goji barry thinking',
    text: 'Great! Let’s start by figuring out your current plan...',
    subtext: '...so we can recommend a better one.',
    nextMessageKey: 'current_plan__current_carrier'
  },

  current_plan__current_carrier: {
    from: 'bot',
    type: MessageType.STEP,
    endpoint: '/llm/current-carrier',
    question: [
      {
        text: 'Who is your current carrier?'
      }
    ],
    outOfContextResponse: `${outOfContextTemplate}who your current carrier is?`,
    suggestions: [
      {
        type: SuggestionType.TEXT,
        text: 'Verizon',
        value: {
          parsedAnswer: {
            carrierId: '12a6a718-a734-4a77-9ba5-9a3b09835592',
            carrierName: 'Verizon'
          }
        }
      },
      {
        type: SuggestionType.TEXT,
        text: 'AT&T',
        value: {
          parsedAnswer: {
            carrierId: '2b9efb82-eece-48fd-af34-71c28592a014',
            carrierName: 'AT&T'
          }
        }
      },
      {
        type: SuggestionType.TEXT,
        text: 'T-Mobile',
        value: {
          parsedAnswer: {
            carrierId: 'a932774b-f6a7-4352-a747-e498fb0959cc',
            carrierName: 'T-Mobile'
          }
        }
      }
    ],
    interactions: [ChatInteraction.FREE_TEXT],
    nextPaths: {
      $default: 'current_plan__num_lines'
    }
  },

  current_plan__num_lines: {
    from: 'bot',
    type: MessageType.STEP,
    endpoint: '/llm/num-lines-current-plan',
    question: [
      {
        text: 'How many lines do you have with your current plan?'
      }
    ],
    outOfContextResponse: `${outOfContextTemplate}how many lines you have with your current plan?`,
    getInvalidResponse: (validation?: getInvalidResponseParams) => {
      return `Could you tell a value between ${validation?.min} and ${validation?.max} lines?`
    },
    subtitle: 'Make sure to include yourself in this number.',
    interactions: [ChatInteraction.FREE_TEXT],
    suggestions: [
      { type: SuggestionType.TEXT, text: '1 Line', value: { parsedAnswer: 1 } },
      { type: SuggestionType.TEXT, text: '2 Lines', value: { parsedAnswer: 2 } },
      { type: SuggestionType.TEXT, text: '3 Lines', value: { parsedAnswer: 3 } }
    ],
    nextPaths: {
      $default: 'current_plan__plan_type'
    }
  },

  current_plan__plan_type: {
    from: 'bot',
    type: MessageType.STEP,
    endpoint: '/llm/plan-type',
    // TODO: should plan type be used in the recommendations algorithm?
    question: [
      {
        text: 'Do you pay a bill each month or do you pay for minutes and data in advance?'
      }
    ],
    outOfContextResponse: `${outOfContextTemplate}if you have a prepaid or postpaid plan?`,
    getInvalidResponse: (validation?: getInvalidResponseParams) => {
      return `Could you tell me if you have a ${validation?.options?.join(' or ')} plan?`
    },
    suggestions: [
      {
        type: SuggestionType.TEXT,
        text: 'Pay in advance (prepaid)',
        value: { parsedAnswer: 'Prepaid' }
      },
      {
        type: SuggestionType.TEXT,
        text: 'Pay each month (postpaid)',
        value: { parsedAnswer: ContractType.Postpaid }
      }
    ],
    interactions: [ChatInteraction.FREE_TEXT],
    nextPaths: {
      Prepaid: 'current_plan__target_budget',
      $default: 'current_plan__switch_to_prepaid'
    }
  },

  current_plan__switch_to_prepaid: {
    from: 'bot',
    type: MessageType.STEP,
    // TODO: is this the same as plan-type?
    // If user answered they have a prepaid plan, how do we know we should not show this?
    endpoint: '/llm/switch-to-prepaid',
    question: [
      {
        text: 'To save money, would you consider switching to a prepaid plan?'
      }
    ],
    outOfContextResponse: `${outOfContextTemplate}if you would consider switching to a prepaid plan to save money?`,
    getInvalidResponse: (validation?: getInvalidResponseParams) => {
      return `Options you can tell me are ${validation?.options
        ?.slice(0, -1)
        .join(', ')} or ${validation?.options?.slice(-1)}.`
    },
    subtitle: 'Some of these carriers include Cricket Wireless, Mint Mobile, Metro by T-Mobile, and more.',
    suggestions: [
      { type: SuggestionType.TEXT, text: 'Yes', value: { parsedAnswer: 'Yes' } },
      { type: SuggestionType.TEXT, text: 'No', value: { parsedAnswer: 'No' } },
      {
        type: SuggestionType.TEXT,
        text: 'I’m not sure / No preference',
        value: { parsedAnswer: null, resultStatus: ValidatedResults.UNKNOWN }
      }
    ],
    interactions: [ChatInteraction.FREE_TEXT],
    nextPaths: {
      $default: 'current_plan__target_budget'
    }
  },

  current_plan__target_budget: {
    from: 'bot',
    type: MessageType.STEP,
    endpoint: '/llm/monthly-bill',
    subtitle: "If you don't know it, go ahead and type in the most you want to spend per month.",
    question: [
      {
        text: 'What is your total monthly bill for your current plan?'
      }
    ],
    outOfContextResponse: `${outOfContextTemplate}how much you pay for your current plan (per month)?`,
    getInvalidResponse: (validation?: getInvalidResponseParams) => {
      return `Could you tell me a value between $${validation?.min} and $${validation?.max}?`
    },
    suggestions: [],
    interactions: [ChatInteraction.FREE_TEXT],
    nextPaths: {
      $default: 'current_plan__transition__basics_message'
    }
  },

  current_plan__transition__basics_message: {
    type: MessageType.TRANSITION,
    from: 'bot',
    imageUrl: '/assets/images/barry/barry_icon_thinking.svg',
    imageAlt: 'goji barry talking on the phone',
    text: 'Great! Now that we have the basics about your current plan...',
    subtext: "Let's move on to finding an even better one!",
    nextMessageKey: 'current_plan__unhappy_reasons'
  },

  current_plan__unhappy_reasons: {
    from: 'bot',
    type: MessageType.STEP,
    endpoint: '/llm/unhappy-reasons',
    question: [
      {
        text: "What's the main reason you're looking for a new plan?"
      }
    ],
    outOfContextResponse: `${outOfContextTemplate}the main reason you're looking for a new plan?`,
    getInvalidResponse: (validation?: getInvalidResponseParams) => {
      return `Options you can tell me are ${validation?.options?.join(', ')}.`
    },
    interactions: [ChatInteraction.FREE_TEXT],
    suggestions: [
      {
        type: SuggestionType.TEXT,
        text: 'I want a less expensive plan',
        value: { parsedAnswer: [ReasonForSwitch.Cost] }
      },
      { type: SuggestionType.TEXT, text: 'I have bad coverage', value: { parsedAnswer: [ReasonForSwitch.Network] } },
      {
        type: SuggestionType.TEXT,
        text: 'Both of the above',
        value: { parsedAnswer: [ReasonForSwitch.Cost, ReasonForSwitch.Network] }
      }
    ],
    nextPaths: {
      $default: 'all__data_allowance'
    }
  },

  all__data_allowance: {
    from: 'bot',
    type: MessageType.STEP,
    endpoint: '/llm/data-allowance',
    question: [
      {
        text: 'How much data will you need per month?'
      }
    ],
    outOfContextResponse: `${outOfContextTemplate}how much data you will need per month?`,
    getInvalidResponse: (validation?: getInvalidResponseParams) => {
      return `Options you can tell me are ${validation?.options
        ?.slice(0, -1)
        .join(', ')} or ${validation?.options?.slice(-1)}.`
    },
    interactions: [ChatInteraction.FREE_TEXT],
    suggestions: [
      { type: SuggestionType.TEXT, text: 'Unlimited', value: { parsedAnswer: 'Unlimited' } },
      // TODO: line breaks support
      { type: SuggestionType.TEXT, text: "At least 15GB (you're mainly on cellular)", value: { parsedAnswer: 15 } },
      { type: SuggestionType.TEXT, text: "At least 5GB (you're mainly on Wi-Fi)", value: { parsedAnswer: 5 } },
      { type: SuggestionType.TEXT, text: 'Barely any', value: { parsedAnswer: 0 } }
    ],
    nextPaths: { $default: 'all__plan_needs' }
  },

  all__plan_needs: {
    from: 'bot',
    type: MessageType.STEP,
    endpoint: '/llm/must-haves',
    question: [
      {
        text: 'Last question! Are any of the following options a must have?'
      }
    ],
    outOfContextResponse: `${outOfContextTemplate}which of the following options are must haves?`,
    getInvalidResponse: (validation?: getInvalidResponseParams) => {
      return `Options you can tell me are ${validation?.options?.join(', ')}.`
    },
    interactions: [ChatInteraction.FREE_TEXT],
    suggestions: [
      {
        type: SuggestionType.TEXT,
        text: 'Bundled streaming service (like Netflix)',
        value: { parsedAnswer: ['StreamingServices'] }
      },
      { type: SuggestionType.TEXT, text: 'Hotspot', value: { parsedAnswer: ['Hotspot'] } },
      { type: SuggestionType.TEXT, text: 'Roaming', value: { parsedAnswer: ['Roaming'] } },
      { type: SuggestionType.TEXT, text: 'Premium/Prioritized Data', value: { parsedAnswer: ['PremiumData'] } },
      { type: SuggestionType.TEXT, text: 'None of the above', value: { parsedAnswer: [] } }
    ],
    nextPaths: { $default: 'all__transition__final_message' }
  },

  all__transition__final_message: {
    type: MessageType.TRANSITION,
    from: 'bot',
    imageUrl: '/assets/images/barry/barry_icon_swipe.svg',
    imageAlt: 'goji barry swiping plans',
    text: 'Ok, before we make our final recommendations, let’s see if we’re on the right track.',
    subtext: 'We’re going to show you 5 potential plans. Swipe to tell us which ones you like (or don’t like).',
    nextMessageKey: undefined
  }
} as const

export const ChatFlow = ChatFlowConst as unknown as DeepMutable<typeof ChatFlowConst>

const ChatFlowSelectorConst = {
  entrypoint: {
    type: MessageType.TRANSITION,
    from: 'bot',
    imageUrl: '/assets/images/barry/barry_icon_ok_hand.svg',
    imageAlt: 'goji barry signaling ok with his hand',
    text: 'Hi there, I’m Barry!',
    subtext: 'What can I help you with?',
    nextMessageKey: 'question__start_plan_finder'
  },

  question__start_plan_finder: {
    from: 'bot',
    type: MessageType.STEP,
    endpoint: 'empty',
    question: [],
    outOfContextResponse: `${outOfContextTemplate}what I can help you with?`,
    interactions: [ChatInteraction.FREE_TEXT],
    suggestions: [
      {
        type: SuggestionType.LINK_WITH_ICON,
        routerLink: RoutePaths.chatFinder,
        text: 'Plans',
        src: '/assets/icons/footer/plans.svg',
        size: 'large',
        value: {
          parsedAnswer: 'ChatFlow'
        }
      },
      {
        type: SuggestionType.TOOL_SELECTION,
        toolName: 'CoverageMap' as TOOL_NAME.COVERAGE_MAP,
        text: 'Coverage',
        src: '/assets/icons/footer/map.svg',
        size: 'large',
        value: {
          parsedAnswer: 'CoverageMap' as TOOL_NAME.COVERAGE_MAP
        },
        featureFlag: FeatureFlag.GEN_UI_COVERAGE
      },
      {
        type: SuggestionType.TOOL_SELECTION,
        toolName: 'NetworkMeter' as TOOL_NAME.NETWORK_METER,
        text: 'Network Meter',
        src: '/assets/icons/footer/meter.svg',
        size: 'large',
        value: {
          parsedAnswer: 'NetworkMeter' as TOOL_NAME.NETWORK_METER
        },
        featureFlag: FeatureFlag.GEN_UI_METER
      }
    ],
    nextPaths: {
      $default: undefined
    }
  },

  question__start_plan_finder_free: {
    from: 'bot',
    type: MessageType.STEP,
    endpoint: 'empty',
    question: [],
    outOfContextResponse: `${outOfContextTemplate}what I can help you with?`,
    interactions: [ChatInteraction.FREE_TEXT],
    suggestions: [
      {
        type: SuggestionType.TEXT_WITH_ICON,
        routerLink: RoutePaths.chatFinder,
        text: 'Plans',
        src: '/assets/icons/footer/plans.svg',
        size: 'large',
        value: {
          parsedAnswer: 'ChatFlow'
        }
      },
      {
        type: SuggestionType.TOOL_SELECTION,
        toolName: 'CoverageMap' as TOOL_NAME.COVERAGE_MAP,
        text: 'Coverage',
        src: '/assets/icons/footer/map.svg',
        size: 'large',
        value: {
          parsedAnswer: 'CoverageMap' as TOOL_NAME.COVERAGE_MAP
        },
        featureFlag: FeatureFlag.GEN_UI_COVERAGE
      },
      {
        type: SuggestionType.TOOL_SELECTION,
        toolName: 'NetworkMeter' as TOOL_NAME.NETWORK_METER,
        text: 'Network Meter',
        src: '/assets/icons/footer/meter.svg',
        size: 'large',
        value: {
          parsedAnswer: 'NetworkMeter' as TOOL_NAME.NETWORK_METER
        },
        featureFlag: FeatureFlag.GEN_UI_METER
      }
    ],
    nextPaths: {
      $default: undefined
    }
  },

  coverage__start: {
    from: 'bot',
    type: MessageType.STEP,
    endpoint: 'empty',
    question: [
      {
        text: 'Sure! Here are some suggestions...'
      }
    ],
    outOfContextResponse: `${outOfContextTemplate}what do you want to know about your coverage?`,
    interactions: [ChatInteraction.FREE_TEXT],
    suggestions: [
      {
        type: SuggestionType.TEXT,
        text: 'What does my coverage look like?',
        value: {
          parsedAnswer: 'What does my coverage look like?'
        }
      },
      {
        type: SuggestionType.TEXT,
        text: "What's the best carrier for my location?",
        value: {
          parsedAnswer: 'What is the best carrier for my location?'
        }
      }
    ],
    nextPaths: {
      $default: undefined
    }
  },

  meter__start: {
    from: 'bot',
    type: MessageType.STEP,
    endpoint: 'empty',
    question: [
      {
        text: 'Sure! Here are some suggestions...'
      }
    ],
    outOfContextResponse: `${outOfContextTemplate}what do you want to know about your coverage?`,
    interactions: [ChatInteraction.FREE_TEXT],
    suggestions: [
      {
        type: SuggestionType.TEXT,
        text: 'Run a speed test',
        value: {
          parsedAnswer: 'Run a speed test'
        }
      },
      {
        type: SuggestionType.TEXT,
        text: 'Show my most recent network tests',
        value: {
          parsedAnswer: 'Show my most recent network tests'
        }
      }
    ],
    nextPaths: { $default: undefined }
  }
} as const

export const ChatFlowSelector = ChatFlowSelectorConst as DeepMutable<typeof ChatFlowSelectorConst>

const QuickQuestionsConst = {
  what_can_i_help_you_with: {
    from: 'bot',
    type: MessageType.TEXT,
    text: 'Is there anything else I can help with?'
  }
} as const

export const QuickQuestions = QuickQuestionsConst as DeepMutable<typeof QuickQuestionsConst>
