import { IonRouterLink } from '@ionic/react'
import { getBoundsFromPlaceId } from '../../utils/Address'
import MapComponent from '../Map/Map'
import React from 'react'
import { DeckManager } from '../CoverageMap/DeckManager'
import { useCoverageMapLogic } from '../CoverageMap/CoverageMapComponent/useCoverageMap'
import { UseCarrier } from '../../services/Carrier.service'
import { useQuery } from 'react-query'
import { Address } from '../../store/address'
import { CoverageMapCardStyles } from './CoverageMapCard.styles'

export interface RegionCoverageMapCardProps {
  address: Address
  requestedCarrierId?: string
  technology?: '5G' | '4G'
  title?: string
}

const RegionCoverageMapCard = ({ address, requestedCarrierId, technology, title }: RegionCoverageMapCardProps) => {
  const requestedCarrier = UseCarrier.useCarrierWithPerformance({
    carrierId: requestedCarrierId,
    addresses: [address]
  })
  const bestCarrier = UseCarrier.useBestCarrierWithPerformance({ addresses: [address] })

  const carrierToDisplay = requestedCarrier.data ?? bestCarrier.data

  const { data: bounds } = useQuery(['bounds', address.placeId], () => getBoundsFromPlaceId(address.placeId), {
    enabled: !!address.placeId
  })
  const { deckManagerRef, setGoogleMap } = useCoverageMapLogic()

  const customizeMap = React.useCallback(
    (map: google.maps.Map) => {
      deckManagerRef.current = new DeckManager(map, carrierToDisplay?.carrier?.id ?? '')
      deckManagerRef.current.setNetworkType(technology?.toUpperCase() === '4G' ? 0 : 1)
      deckManagerRef.current.setup()

      setGoogleMap(map)
    },
    [deckManagerRef, carrierToDisplay?.carrier?.id, technology, setGoogleMap]
  )

  return (
    <div className={CoverageMapCardStyles}>
      <span className='title'>{title ?? address?.label ?? address?.addressName}</span>
      <div className='subtitle'>
        <span className='text'>{address?.addressName}</span>
      </div>
      <div className='coverage-map'>
        {!!bounds && (
          <MapComponent
            center={bounds!.getCenter()}
            restriction={{
              latLngBounds: bounds!,
              strictBounds: false
            }}
            highlightedRegionPlaceId={address.placeId}
            customizeMap={customizeMap}
            zoom={0}
            minZoom={0}
          />
        )}
      </div>
      <IonRouterLink
        routerLink={`/map?technology=${technology}&carrier=${carrierToDisplay?.carrier?.id}&placeId=${address.placeId}`}
        routerDirection='forward'
        color='dark'
        className='map-link'
      >
        View in map
      </IonRouterLink>
    </div>
  )
}

export default RegionCoverageMapCard
