import { useGeneralStore } from '../../../../store'
import { Address } from '../../../../store/address'
import { Logger } from '../../../../utils/Logger'
import { ValidatedResults } from '../../ServerResponse'
import { ChatInteraction, MessageType } from '../../types/ChatStep'
import { FreeBotMessage } from '../../types/free-chat.types'
import { SuggestionType } from '../../types/Suggestion'
import { ChatInterface, ToolHandler } from '../const/tool.const'

// TODO: this messages are not fully approved by client
function prependCarrierMessage(input: CoverageMapCardBackendInput): {
  messages: FreeBotMessage[]
  updatedInput: CoverageMapCardBackendInput
} {
  let messages: FreeBotMessage[] = []
  let updatedInput: CoverageMapCardBackendInput = { ...input }

  if (input.carrierStatus === 'unknown' || input.carrierStatus === 'failed') {
    messages.push({
      type: MessageType.TEXT,
      from: 'bot',
      text: `Looks like we couldn't recognize that carrier. No problem! Here's the best 5G carrier available for your location.`
    })
    updatedInput.wantsBestCarrier = true
  } else if (input.networkType === 'invalid') {
    messages.push({
      type: MessageType.TEXT,
      from: 'bot',
      text: `Hmm, we didn't recognize that mobile generation. No worries—check out the 5G coverage details we found for you!`
    })
  } else if (input.wantsBestCarrier) {
    messages.push({
      type: MessageType.TEXT,
      from: 'bot',
      text: `Based on this location. The carrier below provides the best ${input.networkType.toUpperCase()} performance.`
    })
  } else {
    messages.push({
      type: MessageType.TEXT,
      from: 'bot',
      text: `Got it! Here's the ${input.networkType.toUpperCase()} coverage for this location.`
    })
  }
  return { messages, updatedInput }
}

export type CoverageMapCardBackendInput = {
  address: string | null
  isCurrentLocation: boolean
  isMissingLocation: boolean
  knownLocation: string | null
  networkType: '4g' | '5g' | 'invalid'
  carrier: string | null
  carrierId: string | null
  carrierStatus: 'valid' | 'failed' | 'unknown' | null
  wantsBestCarrier: boolean
  // Front-end custom params
  placeId?: string
}

export class CoverageMapSuggestionHandler {
  private coverageMapToolHandler: CoverageMapToolHandler
  private toolCall: ChatInterface<CoverageMapCardBackendInput>['toolCall']

  constructor(address: Address, chatInterface: Omit<ChatInterface<any>, 'toolCall'>) {
    this.toolCall = {
      name: 'CoverageMap',
      input: {
        address: address.addressName,
        placeId: address.placeId,
        knownLocation: address.label ?? null,
        isCurrentLocation: false,
        isMissingLocation: false,
        carrier: null,
        carrierId: null,
        carrierStatus: null,
        networkType: '5g',
        wantsBestCarrier: false
      }
    }
    this.coverageMapToolHandler = new CoverageMapToolHandler({ ...chatInterface, toolCall: this.toolCall })
  }
  public addToolMessages() {
    const { messages, updatedInput } = prependCarrierMessage(this.toolCall.input)
    messages.forEach((message) => {
      this.coverageMapToolHandler.onMessage(message)
    })
    this.toolCall.input = updatedInput
    this.coverageMapToolHandler.onMessage({
      type: MessageType.TOOL,
      from: 'bot',
      tool: 'CoverageMap',
      toolComponent: 'CoverageMapCardToolComponent',
      backendInput: this.toolCall.input
    })

    this.coverageMapToolHandler.showSuggestions()
  }
}

export class CoverageMapToolHandler implements ToolHandler<CoverageMapCardBackendInput> {
  constructor(private chatInterface: ChatInterface<CoverageMapCardBackendInput>) {}

  onMessage(message: FreeBotMessage) {
    this.chatInterface.onMessage({
      messageRecord: { message }
    })
  }

  showSuggestions() {
    this.onMessage({
      from: 'bot',
      type: MessageType.STEP,
      question: [{ text: 'Is there anything else I can help with?' }],
      interactions: [ChatInteraction.FREE_TEXT],
      suggestions: [
        {
          type: SuggestionType.TEXT,
          text: 'Run a speed test',
          value: { parsedAnswer: 'Run a speed test', resultStatus: ValidatedResults.VALID }
        },
        {
          type: SuggestionType.TEXT,
          text: 'Find me the perfect plan',
          value: { parsedAnswer: 'Find me the perfect plan', resultStatus: ValidatedResults.VALID }
        }
      ]
    })
  }

  async handle() {
    const addresses = useGeneralStore.getState().addresses
    if (this.chatInterface.toolCall.input.isMissingLocation) {
      this.onMessage({
        from: 'bot',
        question: [{ text: 'Please provide an address to check the coverage.' }],
        type: MessageType.STEP,
        interactions: [ChatInteraction.ADDRESS],
        suggestions: [
          {
            type: SuggestionType.CURRENT_LOCATION,
            text: 'Current Location',
            value: { parsedAnswer: 'Current Location', resultStatus: ValidatedResults.VALID }
          },
          ...addresses.map((address) => ({
            type: SuggestionType.LOCATION,
            location: address,
            text: address.label,
            value: { parsedAnswer: address.label, resultStatus: ValidatedResults.VALID }
          }))
        ]
      })
      return
    }

    const backendInput: CoverageMapCardBackendInput = {
      ...this.chatInterface.toolCall.input
    }

    if (this.chatInterface.toolCall.input.isCurrentLocation) {
      this.onMessage({
        from: 'bot',
        question: [{ text: 'Sure! Which location would you like to check?' }],
        subtitle: "Type a location or select a label you've saved.",
        type: MessageType.STEP,
        interactions: [ChatInteraction.ADDRESS],
        suggestions: [
          {
            type: SuggestionType.CURRENT_LOCATION,
            text: 'Current Location',
            value: { parsedAnswer: 'Current Location', resultStatus: ValidatedResults.VALID }
          },
          ...addresses.map((address) => ({
            type: SuggestionType.LOCATION,
            location: address,
            text: address.label,
            value: { parsedAnswer: address.label, resultStatus: ValidatedResults.VALID }
          }))
        ]
      })
      return
    }

    // CASE: Known location
    if (this.chatInterface.toolCall.input.knownLocation) {
      Logger.warn('knownLocation: WIP')
      if (!addresses.find((address) => address.label === this.chatInterface.toolCall.input.knownLocation)) {
        this.onMessage({
          from: 'bot',
          type: MessageType.STEP,
          question: [{ text: "Sure, but I don't know where that is. Can you provide an address?" }],
          interactions: [ChatInteraction.ADDRESS],
          suggestions: [
            {
              type: SuggestionType.CURRENT_LOCATION,
              text: 'Current Location',
              value: { parsedAnswer: 'Current Location' }
            },
            ...addresses.map((address) => ({
              type: SuggestionType.LOCATION,
              location: address,
              text: address.label,
              value: { parsedAnswer: address.label }
            }))
          ]
        })
        return
      }
    }

    const { messages, updatedInput } = prependCarrierMessage(backendInput)
    messages.forEach((message) => {
      this.onMessage(message)
    })

    this.onMessage({
      from: 'bot',
      type: MessageType.TOOL,
      backendInput: updatedInput,
      tool: this.chatInterface.toolCall.name,
      toolComponent: 'CoverageMapCardToolComponent'
    })

    this.showSuggestions()
  }
}
