import { IonCol, IonGrid, IonIcon, IonRouterLink, IonRow } from '@ionic/react'
import { Address, LabelType } from '../../store/address'
import React from 'react'
import { bookmark, briefcase, home } from 'ionicons/icons'
import NetworkPerformanceDiagram from '.././NetworkPerformanceDiagram/NetworkPerformanceDiagram'
import CarrierDisplay from '.././CarrierDisplay/CarrierDisplay'
import { UseCarrier } from '../../services/Carrier.service'
import { Carrier } from '../../store/carrierSelector'
import { useAutoChatStorage } from '../../pages/llm/hooks/useChatStorage'
import ReducedCoverageMap from '.././CoverageMap/CoverageMapComponent/ReducedCoverageMap'
import { ChartStyles, CoverageMapCardStyles } from './CoverageMapCard.styles'

interface CarrierRowProps {
  carrier?: Carrier
  technology?: '5G' | '4G' | 'Both'
  performance4G?: number
  performance5G?: number
  title: string
}

export const CarrierRow = (props: CarrierRowProps) => {
  const show4G = props.technology === '4G' || props.technology === 'Both'
  const show5G = props.technology === '5G' || props.technology === 'Both'
  return (
    <>
      <IonRow className='description'>
        <IonCol className='carrier'>{props.title}</IonCol>
        {show5G && <IonCol>5G</IonCol>}
        {show4G && <IonCol>4G</IonCol>}
      </IonRow>
      <IonRow className='value'>
        <IonCol>
          <CarrierDisplay className='carrier-display' carrier={props.carrier ?? { name: 'No Carrier', logo_url: '' }} />
        </IonCol>
        {show5G && (
          <IonCol>
            <NetworkPerformanceDiagram performance={props.performance4G ?? 0} />
          </IonCol>
        )}
        {show4G && (
          <IonCol>
            <NetworkPerformanceDiagram performance={props.performance5G ?? 0} />
          </IonCol>
        )}
      </IonRow>
    </>
  )
}

export interface CoverageMapCardProps {
  address: Address
  requestCarrierId?: string
  technology?: '5G' | '4G' | 'Both'
  title?: string
}

const getCarrierTitle = (requestedCarrier: any, currentCarrier: any, bestCarrier: any, technology: string) => {
  if (requestedCarrier) return 'Carrier'
  if (currentCarrier) return 'Your Carrier'
  if (bestCarrier) return `Best ${technology} Carrier`
  return 'Carrier'
}

const CoverageMapCard = ({ address, requestCarrierId, technology = '5G', title }: CoverageMapCardProps) => {
  const icon = React.useMemo(() => {
    switch (address.labelType) {
      case LabelType.Home:
        return home
      case LabelType.Work:
        return briefcase
      default:
        return bookmark
    }
  }, [address.labelType])

  const { userPreferences } = useAutoChatStorage()
  const requestedCarrier = UseCarrier.useCarrierWithPerformance({
    carrierId: requestCarrierId,
    addresses: [address]
  }).data
  const currentCarrier = UseCarrier.useCarrierWithPerformance({
    carrierId: userPreferences?.carrierId,
    addresses: [address]
  }).data
  const bestCarrier = UseCarrier.useBestCarrierWithPerformance({ addresses: [address] }).data

  const carrierToDisplay = requestedCarrier || currentCarrier || bestCarrier
  const carrierTitle = getCarrierTitle(requestedCarrier, currentCarrier, bestCarrier, technology)

  return (
    <div className={CoverageMapCardStyles}>
      <span className='title'>{title ?? address.label ?? address.addressName}</span>
      <div className='subtitle'>
        <IonIcon className='icon' icon={icon} />
        <span className='text'>{address.addressName}</span>
      </div>
      <div className='coverage-map'>
        <ReducedCoverageMap addresses={[address]} />
      </div>
      <IonGrid className={ChartStyles}>
        {!!carrierToDisplay && (
          <CarrierRow
            title={carrierTitle}
            carrier={carrierToDisplay.carrier}
            technology={technology.toUpperCase() as '5G' | '4G' | 'Both'}
            performance4G={carrierToDisplay.networkPerformance.average4GPerformance}
            performance5G={carrierToDisplay.networkPerformance.average5GPerformance}
          />
        )}
      </IonGrid>
      <IonRouterLink
        routerLink={`/map?technology=${technology}&carrier=${carrierToDisplay?.carrier?.id}&placeId=${address.placeId}`}
        routerDirection='forward'
        color='dark'
        className='map-link'
      >
        View in map
      </IonRouterLink>
    </div>
  )
}

export default CoverageMapCard
