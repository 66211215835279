import debounce from 'lodash/debounce'
import API from '../../../api'
import { ProfileService } from '../../../services/backend-sync/profile.service'
import chatService from '../../../services/chat/Chat.service'
import { ChatFlowSelector } from '../ChatFlow'
import { ChatTimelineProps } from '../components/ChatTimeline'
import { IChatFlow } from '../types/ChatStep'
import { PredefinedChatStorage, PredefinedChatStorageSource } from '../types/ChatStorageSource'

export type FreeChat = {
  chatTimeline: ChatTimelineProps['timeline']
}

export function getDefaultTimeline() {
  return [ChatFlowSelector.entrypoint, ChatFlowSelector.question__start_plan_finder_free]
}

export function getFreeChatApiSource() {
  return {
    getStorage: async (): Promise<FreeChat> => {
      const response: { free_chat_state: FreeChat } = await API.get('/profile/free-chat-state')
      if (!response?.free_chat_state?.chatTimeline || response.free_chat_state.chatTimeline.length === 0) {
        return { chatTimeline: getDefaultTimeline() }
      }
      return response.free_chat_state
    },
    setStorage: async (freeChat: FreeChat | undefined): Promise<FreeChat> => {
      const response: { free_chat_state: FreeChat } = await API.put('/profile/free-chat-state', freeChat as any)
      return response.free_chat_state
    }
  }
}

export function getPlanChatApiSource<T extends IChatFlow>(): PredefinedChatStorageSource<T> {
  return {
    staleTime: Infinity,
    getStorage: async () => {
      const profile = await ProfileService.get().getBackendUser()
      return profile.chat_state as PredefinedChatStorage<T>
    },
    setStorage: debounce(
      async (value) => {
        await chatService.uploadChatStorage(value)
      },
      100,
      { trailing: true, leading: false }
    ) as (value: PredefinedChatStorage<T> | undefined) => Promise<void>
  }
}
